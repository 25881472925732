<template>
  <div class="v-application--wrap">
    <v-app-bar app>
      <v-toolbar-title style="display: inline-table;">
        <v-avatar size="32px" tile class="px-1">
          <img alt="VVA" :src="require('@/../public/img/icons/LOGO.png')" width="32">
        </v-avatar>
      </v-toolbar-title>
      <v-spacer/>
    </v-app-bar>
    <v-main>
      <transition mode="out-in" name="fade-transform">
        <keep-alive>
          <v-card tile :elevation="0" class="layout-card-content" width="100%">
              <v-layout wrap>
                <v-card-text class="white--text pa-3">
                  <div style="color: #4a5568">
                    <h1>Search Tracking Number</h1>
                  </div>
                </v-card-text>
                <v-layout wrap class="search-fields">
                  <v-text-field dense outlined @keyup.enter="doSearch" v-model="trackingNumber"
                                class="mr-2 ml-2" label="enter Order Code or Tracking Number"/>
                  <v-btn color="primary" @click="doSearch" :disabled="trackingNumber === ''">
                    <v-icon>mdi-magnify</v-icon>
                    Search
                  </v-btn>
                </v-layout>
              </v-layout>
              <v-layout wrap class="pa-3" v-if="trackingHistoryData">
                <v-card class="elevation-5 pa-3 timeline">
                  <div>
                    <div class="pa-3 block" style="display: flex; justify-content: space-between;flex-direction: column;">
                      <div>
                        <div>
                          <span class="mr-2">Tracking Number:</span>
                          <span class="font-weight-bold">{{ trackingHistoryData.tracking_number }}</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span class="mr-2">Current status:</span>
                          <span class="font-weight-bold text-uppercase">{{ deliverStatus }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="pa-3 block mt-2" v-if="trackingHistoryData.details">
                      <v-timeline>
                        <v-timeline-item
                          v-for="(detail, i) in trackingHistoryData.details"
                          :key="i"
                          small
                          right
                        >
                          <template v-slot:opposite>
                          <span
                            :class="`headline`"
                            v-text="new Date(detail.in_time).toISOString().substr(0, 19).replace('T', ' ')"
                          ></span>
                          </template>
                          <div class="py-4">
<!--                            <h2 :class="`headline font-weight-light mb-4  font-weight-bold text-capitalize`">
                              {{detail.data.status}}
                            </h2>-->
                            <div>
                              {{detail.message}}
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </div>
                  </div>
                </v-card>
              </v-layout>
          </v-card>
        </keep-alive>
      </transition>
    </v-main>
    <v-footer app inset padless dense>
      <v-card class="secondary text-left" flat tile width="100%" height="40px">
        <v-layout wrap>
          <v-card-text class="white--text pa-3">
            <div style="display: flex; justify-content: space-between; align-items: center">
              <span>support@epacket.vn</span>
            </div>
          </v-card-text>
        </v-layout>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'TrackingNumberHistory',
  data: () => ({
    trackingNumber: '',
    trackingHistoryData: null,
    deliverStatus: '',
  }),
  computed: {
    ...mapGetters({
      backgroundImage: 'settings/backgroundImage'
    })
  },
  methods: {
    ...mapActions({
      getTrackingNumberHistory: 'tracking_number_history/getTrackingNumberHistory'
    }),
    async doSearch() {
      if (this.trackingNumber === '') {
        this.noticeError('Bạn cần nhập mã tracking number!');
        return;
      }
      let res = await this.getTrackingNumberHistory(this.trackingNumber);
      console.log('res.data', res.data);
      if (res.data.data) {
        this.trackingHistoryData =  res.data.data
        if (this.trackingHistoryData.details && this.trackingHistoryData.details.length > 0) {
          this.trackingHistoryData.details.sort((b,a) => a.in_time.localeCompare(b.in_time));
          this.trackingHistoryData.details.forEach(detail => {
            if (detail.message && detail.message !== 'error' && !this.deliverStatus) {
              this.deliverStatus = detail.message
            }
          })
        }
      } else {
        this.trackingHistoryData = null
        this.noticeWarning('Không có dữ liệu!');
      }
    },
  },
  mounted() {
    if (this.$route.params && this.$route.params.trackingNumber) {
      this.trackingNumber = this.$route.params.trackingNumber
      this.doSearch()
    }
  },
};
</script>
<style scoped>
.search-fields {
  max-width: 400px;
  margin: 0 auto;
}
.timeline {
  max-width: 1000px;
  margin: 0 auto;
}
</style>
